import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from './config/constants';
import { AnnouncementDeliveryStatus } from './model/entity/announcement/announcement-delivery-status';
import { DeviceCommonSpecific } from './model/entity/announcement/device-common-specific';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementDeliveryStatusService {
  /**
   * URL for common simple playlist
   */
  announcementDeliveryStatusUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/announcement-delivery-status';
  /**
   * URL for common simple playlist
   */
  simpleDeliveryStatusUrl = Constant.BACKEND_URL + Constant.DSC_SERVICE_URL + 'api/simple-delivery-status';
  /**
   * URL of device publish info API
   */
  deliveryStatusTimetableUrl = Constant.BACKEND_URL + Constant.COMMON_SERVICE_URL + 'api/delivery-status-timetable';
  constructor(private http: HttpClient) {}
  /**
   * Be used SIMPLE-SIGNAGE-EDITOR
   * get delivery status for devices
   * @returns
   */
  getDeliveryStatusForDevices(): Observable<Array<AnnouncementDeliveryStatus>> {
    return this.http.get<Array<AnnouncementDeliveryStatus>>(`${this.announcementDeliveryStatusUrl}/get-delivery-status-for-devices`);
  }

  /**
   * Be used SIMPLE-SIGNAGE-EDITOR
   * update status first time for devices
   *
   * @param deviceIds
   */
  updateStatusFirstTimeForDevices(deviceIds: Array<Number>): Observable<Array<AnnouncementDeliveryStatus>> {
    return this.http.post<Array<AnnouncementDeliveryStatus>>(
      `${this.announcementDeliveryStatusUrl}/first-time/update-status-for-devices`,
      deviceIds
    );
  }

  /**
   * Be used SIMPLE-SIGNAGE-EDITOR
   * update status first time for devices
   *
   * @param deviceIds
   */
  updateStatusForDevices(deviceIds: Array<Number>): Observable<Array<AnnouncementDeliveryStatus>> {
    return this.http.post<Array<AnnouncementDeliveryStatus>>(`${this.announcementDeliveryStatusUrl}/update-status-for-devices`, deviceIds);
  }

  /**
   * Be used SIMPLE-SIGNAGE-EDITOR
   * cancel delivery
   *
   * @param deviceIds
   */
  cancelDelivery(deviceIds: Array<Number>): Observable<Array<string>> {
    return this.http.post<Array<string>>(`${this.announcementDeliveryStatusUrl}/cancel-delivery`, deviceIds);
  }

  /**
   * Update detail status for device
   *
   * @param deviceInfo
   * @returns
   */
  public updateDetailStatusForDevice(deviceInfo: DeviceCommonSpecific): any {
    return this.http.post(`${this.announcementDeliveryStatusUrl}/update-detail-status-for-device`, deviceInfo);
  }

  /**
   * save detail status for devices checked
   *
   * @param deviceInfos
   * @returns
   */
  public saveDetailStatusForDevices(deviceInfos: Array<DeviceCommonSpecific>): Observable<void> {
    return this.http.post<void>(`${this.announcementDeliveryStatusUrl}/save-detail-status-for-devices`, deviceInfos);
  }
  /**
   * get device information
   *
   * @param deviceIds
   * @returns
   */
  public getDeviceInfo(deviceIds: string): Observable<any> {
    const params = new HttpParams().set('deviceIds', deviceIds);
    return this.http.get<any>(`${this.announcementDeliveryStatusUrl}/get-device-information`, { params });
  }

  /**
   * get Status Device Information Timetable Editor
   * @param checkedDevices
   * @returns
   */
  public getStatusDeviceTimetableEditor(checkedDevices: DeviceCommonSpecific[]): Observable<any> {
    return this.http.post<any>(`${this.deliveryStatusTimetableUrl}/get-device-information-not-yet-completed`, checkedDevices);
  }

  /**
   * check exist data sequence
   *
   * @param deviceIds
   * @returns
   */
  checkExistDataSequence(deviceIds: Array<Number>): Observable<boolean> {
    return this.http.post<boolean>(`${this.announcementDeliveryStatusUrl}/check-exist-data-sequences`, deviceIds);
  }

  /**
   * check exist data sequence
   *
   * @param deviceIds
   * @returns
   */
  checkExistPlaylistOfCommonRegistration(deviceIds: Array<Number>): Observable<boolean> {
    return this.http.post<boolean>(`${this.announcementDeliveryStatusUrl}/check-exist-playlist-of-common-registration`, deviceIds);
  }

  /**
   * validate total size of devices
   *
   * @param deviceAnnouncementPlaylists
   * @returns
   */
  validateTotalSizeOfDevices(deviceAnnouncementPlaylists: Array<Number>): Observable<Array<string>> {
    return this.http.post<Array<string>>(
      `${this.announcementDeliveryStatusUrl}/validate-total-size-of-devices`,
      deviceAnnouncementPlaylists
    );
  }
}
